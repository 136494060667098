.rc-tooltip {
    position: absolute;
    z-index: 9;
    display: block;
    visibility: visible;
    font-size: 13px;
    line-height: 19px;
}

.rc-tooltip-inner {
    padding: 8px;
    text-align: left;
    text-decoration: none;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 31px 0 #00000017;
    max-width: 502px;
    font-family: Graphik LCG;
}

.rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.rc-tooltip-hidden {
    display: none;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,.rc-tooltip-placement-topLeft .rc-tooltip-arrow,.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: -6px !important;
    border-width: 6px 6px 0;
    border-top-color: #ffffff
}

.rc-tooltip-placement-right .rc-tooltip-arrow,.rc-tooltip-placement-rightTop .rc-tooltip-arrow,.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: -6px !important;
    border-width: 6px 6px 6px 0;
    border-right-color: #ffffff
}

.rc-tooltip-placement-left .rc-tooltip-arrow,.rc-tooltip-placement-leftTop .rc-tooltip-arrow,.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: -6px !important;
    border-width: 6px 0 6px 6px;
    border-left-color: #ffffff
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: -6px !important;
    border-width: 0 6px 6px;
    border-bottom-color: #ffffff
}


.rc-tooltip.rc-tooltip-zoom-appear,.rc-tooltip.rc-tooltip-zoom-enter {
    opacity: 0
}

.rc-tooltip.rc-tooltip-zoom-enter,.rc-tooltip.rc-tooltip-zoom-leave {
    display: block
}

.rc-tooltip-zoom-enter,.rc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.18,.89,.32,1.28);
    animation-play-state: paused
}

.rc-tooltip-zoom-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.6,-.3,.74,.05);
    animation-play-state: paused
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running
}

@keyframes rcToolTipZoomIn {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
    }

    to {
        opacity: 1;
        transform-origin: 50% 50%;
    }
}

@keyframes rcToolTipZoomOut {
    0% {
        opacity: 1;
        transform-origin: 50% 50%;
    }

    to {
        opacity: 0;
        transform-origin: 50% 50%;
    }
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: calc(50% - 6px) !important;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: calc(15% - 6px) !important;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: calc(15% - 6px) !important;
    left: auto !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: calc(50% - 6px) !important;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: calc(15% - 6px) !important;
    margin-top: 0
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: calc(15% - 6px) !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: calc(50% - 6px) !important;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: calc(15% - 6px) !important;
    margin-top: 0
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: calc(15% - 6px) !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: calc(50% - 6px) !important;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: calc(15% - 6px) !important;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: calc(15% - 6px) !important;
    left: auto !important;
}
